<template>
    <div>
        <div class="menu-item-box" v-for="(item, index) in menuList" :key="index" @click="runMenuItem(index)">
            <img :src="item.icon" class="icon">
            <div class="title">
                {{item.title}}
            </div>
            <div class="state" v-if="item.title==='绑定手机'">
                {{$store.state.bindPhone!==null?'已绑定':'未绑定'}}
            </div>
            <div class="state" v-if="item.title==='账号管理'">
                {{$store.state.isSettingAccountPassword===1?'修改密码':'设置密码'}}
            </div>
            <div class="state" v-if="item.title==='支付密码'">
                {{$store.state.hasPayPassword===1?'修改密码':'设置密码'}}
            </div>
            <div class="state" v-if="item.title==='平台币'">
                {{$store.state.platformCoin+'元'}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            menuList:Array
        },
        data() {
            return {
                
            }
        },
        methods:{
            runMenuItem(index) {
                this.$emit('runMenuItem', index)
            }
        }
    }
</script>

<style scoped>
    .menu-item-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        height: 100px;
        width: 25%;
    }
    .icon {
        height: 48px;
    }
    .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        color: #333333;
        text-align: center;
        height: 17px;
    }
    .state {
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        color: #16C5CE;
        height: 16px;
    }
</style>