<template>
    <div v-if="isShowLogoutWindow" class="logout-dialog">
      <div class="mask"></div>
      <div class="dialog-window">
          <div class="title">
              退出登录
          </div>
          <div class="content">
             是否退出当前账号？
          </div>
          <div class="btn-box">
             <div class="cancel" @click="cacelLogout">取消</div>
             <div class="confirm" @click="confirmLogout">确定</div>
          </div>
      </div>
    </div>
</template>

<script>
    export default {
        components: {
          
        },
        props:{
         isShowLogoutWindow:Boolean
        },
        data() {
            return {
                
            }
        },
        methods: {
            cacelLogout() {
                this.$emit("cacelLogout")
            },
            confirmLogout() {
                this.$emit("confirmLogout")
            }
        }
    }
</script>

<style scoped>
   .logout-dialog {
       position: fixed;
       top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
   }
   .mask {
       position: absolute;
       width: 100%;
       height:100%;
       top: 0;
       left: 0;
       background-color: rgba(0, 0, 0, 0.5);
   }
   .dialog-window {
       padding: 17.5px 18.75px 12px 18.75px;
       border-radius: 5px;
       background-color: #ffffff;
       width: 188px;
       position: relative;
   }
   .title {
       font-size: 11.25px;
       font-weight: 500;
       color: #333333;
       text-align: center;
   }
   .content {
       font-size: 10px;
       font-weight: 400;
       color: #333333;
       text-align: center;
       padding-top: 17px;
   }
   .btn-box {
       display: flex;
       align-items: center;
       justify-content: space-between;
       padding-top: 25px;
   }
   .cancel,
   .confirm {
       width: 68.75px;
       height: 27.5px;
       display: flex;
       align-items: center;
       justify-content: center;
       font-size: 8.75px;
       font-weight: 500;
       border-radius: 7px;
   }
   .cancel {
       border: 1px solid #CCCCCC;
       color: #999999;
   }
   .confirm {
       color: #FFFFFF;
       background: #16C5CE;
   }
</style>
