<template>
    <div class="mine-box">
        <MenuList class="menu-list-box" :menuList="menuList" @runMenuItem="runMenuItem"></MenuList>
    </div>
</template>

<script>
    
    import MenuList from '@/components/Mine/Index/Horizontal/MenuList.vue'
    
    export default {
        components:{
            MenuList
        },
        props:{
            menuList:Array
        },
        data() {
            return {
                
            }
        },
        methods:{
            runMenuItem(index) {
                console.log(index)
                this.$emit('runMenuItem', index)
            }
        }
    }
</script>

<style scoped>
    .mine-box {
        width: 100%;
        height: 100vh;
        padding-top: 49px;
        background-color: #F7FDFE;
    }
    .user-info-box {
        width: 100%;
        height: 84px;
        padding: 27.5px 0 13px 13px;
        border-bottom: 1px solid #F5FAFA;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        overflow: hidden;
    }
    .menu-list-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
</style>