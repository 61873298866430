<template>
    <div>
        <div>
            <Horizontal v-if="$store.getters.currentPageVersion === 'Horizontal'" :menuList="menuList"
                @runMenuItem="runMenuItem"></Horizontal>
            <Vertical v-else :menuList="menuList" @runMenuItem="runMenuItem"></Vertical>
        </div>
        <LogoutDialogHorizontal v-if="$store.getters.currentPageVersion === 'Horizontal'"
            :isShowLogoutWindow="isShowLogoutWindow" @cacelLogout="cacelLogout" @confirmLogout="confirmLogout">
        </LogoutDialogHorizontal>
        <LogoutDialogVertical v-else :isShowLogoutWindow="isShowLogoutWindow" @cacelLogout="cacelLogout"
            @confirmLogout="confirmLogout"></LogoutDialogVertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Mine/Index/Horizontal/Index.vue'
    import Vertical from '@/components/Mine/Index/Vertical/Index.vue'
    import LogoutDialogVertical from '@/components/Mine/Logout/Vertical/Index.vue'
    import LogoutDialogHorizontal from '@/components/Mine/Logout/Horizontal/Index.vue'

    import notice from '@/assets/menuList/icon_notice.svg'
    import account from '@/assets/menuList/icon_account.svg'
    import indent from '@/assets/menuList/icon_indent.svg'
    import cancellation from '@/assets/menuList/icon_cancellation.svg'
    import pay from '@/assets/menuList/icon_pay.svg'
    import phone from '@/assets/menuList/icon_phone.svg'
    import quit from '@/assets/menuList/icon_quit.svg'
    import terrace from '@/assets/menuList/icon_terrace.svg'
    export default {
        components: {
            Horizontal,
            Vertical,
            LogoutDialogVertical,
            LogoutDialogHorizontal
        },
        data() {
            return {
                menuList: [{
                        title: '绑定手机',
                        icon: phone,
                    },
                    {
                        title: '账号管理',
                        icon: account,
                    },
                    {
                        title: '支付密码',
                        icon: pay,
                    },
                    {
                        title: '平台币',
                        icon: terrace,
                    },
                    {
                        title: '游戏订单',
                        icon: indent
                    },
                    {
                        title: '游戏公告',
                        icon: notice
                    },
                    {
                        title: '账号注销',
                        icon: cancellation
                    },
                    {
                        title: '退出登录',
                        icon: quit
                    }
                ],
                isShowLogoutWindow: false, //退出登录确认弹窗



            }
        },
        created() {

        },
        methods: {
            runMenuItem(index) {
                console.log(index)
                switch (index) {
                    case 0:
                        this.$router.push({
                            name: 'BindPhoneIndex',
                            params: {}
                        })
                        break
                    case 1:
                        this.$router.push({
                            name: 'AccountAdminIndex',
                            params: {}
                        })
                        break
                    case 2:
                        this.$router.push({
                            name: 'PayPasswordIndex',
                            params: {}
                        })
                        break
                    case 3:
                        this.$router.push({
                            name: 'PlatformCoinIndex',
                            params: {}
                        })
                        break
                    case 4:
                        this.$router.push({
                            name: 'GameOrderIndex',
                            params: {}
                        })
                        break
                    case 5:
                        this.$router.push({
                            name: 'GameNoticeIndex',
                            params: {}
                        })
                        break
                    case 6:
                        this.$router.push({
                            name: 'LogoffIndex',
                            params: {}
                        })
                        break
                    case 7:
                        this.isShowLogoutWindow = true
                        break
                }
            },
            cacelLogout() {
                this.isShowLogoutWindow = false
            },
            confirmLogout() {
                this.isShowLogoutWindow = false
                this.$common.logout()
            },

        },
        mounted() {
            if (typeof(window.iosGetUserTokenCallback) !== 'function' && this.$common.getNowSystem() === 'ios') {
                window.iosGetUserTokenCallback = this.iosGetUserTokenCallback
                window.iosGetExtInfoCallback = this.iosGetExtInfoCallback
                window.iosGetMiniIdCallback = this.iosGetMiniIdCallback
            }
        },
        // beforeDestroy() {
        //    window.removeEventListener('message', this.listenerFunction) 
        // }
    }
</script>

<style scoped>

</style>