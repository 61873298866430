<template>
    <div class="mine-box">
        <UserInfo class="user-info-box"></UserInfo>
        <MenuList class="menu-list-box" :menuList="menuList" @runMenuItem="runMenuItem"></MenuList>
    </div>
</template>

<script>
    import UserInfo from '@/components/Mine/Index/Vertical/UserInfo.vue'
    import MenuList from '@/components/Mine/Index/Vertical/MenuList.vue'
    
    export default {
        components:{
            UserInfo,
            MenuList
        },
        props:{
            menuList:Array
        },
        data() {
            return {
                
            }
        },
        methods:{
            runMenuItem(index) {
                this.$emit('runMenuItem', index)
            }
        }
    }
</script>

<style scoped>
    .mine-box {
        width: 100%;
        height: 100%;
    }
    .user-info-box {
        width: 100%;
        height: 115px;
        padding: 38px 0 14px 14px;
        border-bottom: 1px solid #F5FAFA;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        overflow: hidden;
    }
    .menu-list-box {
        width: 100%;
        padding: 52px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
</style>